<template>
  <v-card>
    <v-card-title>Settings</v-card-title>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>